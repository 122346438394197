@charset 'UTF-8';
// // Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// // Variables
// @import 'variables';

// // Bootstrap
// @import '~bootstrap/scss/bootstrap';

// @import 'main';
// @import 'pages/categories';
// @import 'pages/category';
// @import 'pages/index';
// @import 'pages/product';

@font-face {
	font-family: ibm-plex-sans;
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/ibm-plex-sans-regular.woff2) format("woff2")
}

@font-face {
	font-family: ibm-plex-sans;
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/ibm-plex-sans-medium.woff2) format("woff2")
}

@font-face {
	font-family: manrope;
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/manrope-regular.woff2) format("woff2")
}

@font-face {
	font-family: manrope;
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/manrope-medium.woff2) format("woff2")
}

@font-face {
	font-family: manrope;
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/manrope-bold.woff2) format("woff2")
}

::-webkit-scrollbar {
	display: none
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

input[type=number] {
	-moz-appearance: textfield
}

*,
:after,
:before {
	-ms-overflow-style: none;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	scrollbar-width: none
}

html {
	scroll-behavior: smooth
}

body {
	color: #000;
	font-family: ibm-plex-sans, sans-serif;
	font-size: 16px;
	margin: 0;
	min-width: 280px;
	overflow-x: hidden;
	position: relative
}

img,
svg,
video {
	display: block
}

img,
video {
	-o-object-fit: cover;
	object-fit: cover
}

a {
	color: inherit;
	display: inline-block;
	outline: none;
	text-decoration: none
}

button,
input {
	background: none;
	border: none;
	font-family: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	outline: none;
	padding: 0;
	text-transform: inherit
}

button {
	cursor: pointer
}

ol,
ul {
	list-style: none;
	padding: 0
}

form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
	margin: 0
}

@media (min-width:1281px) {
	.mobile {
		display: none !important
	}
}

@media (max-width:1280px) {
	.desktop {
		display: none !important
	}
}

.padding {
	padding: 0 20px
}

@media (min-width:1281px) {
	.padding {
		padding: 0
	}
}

.main.main_padding-top {
	padding-top: 20px
}

@media (min-width:1281px) {
	.main.main_padding-top {
		padding-top: 30px
	}
}

.main.main_padding {
	padding: 20px 20px 80px
}

@media (min-width:1281px) {
	.main.main_padding {
		padding: 30px 0 140px
	}

	.container {
		margin: 0 auto;
		max-width: 1280px;
		width: 100%
	}
}

.page__title {
	margin-bottom: 20px
}

h1 {
	color: #09295a;
	font-family: manrope;
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -.02em;
	line-height: 35px
}

@media (min-width:1281px) {
	h1 {
		font-size: 48px;
		line-height: 53px
	}
}

h2 {
	font-family: Manrope;
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -.02em;
	line-height: 35px
}

@media (min-width:1281px) {
	h2 {
		font-size: 48px;
		line-height: 53px
	}
}

.h2-title {
	margin-bottom: 30px
}

@media (min-width:1281px) {
	.h2-title {
		margin-bottom: 50px
	}
}

.h2-title-2 {
	margin-bottom: 30px
}

h3 {
	font-family: manrope;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 33px
}

p {
	font-size: 14px;
	line-height: 17px
}

@media (min-width:1281px) {
	p {
		font-size: 16px
	}
}

.section {
	margin-bottom: 80px
}

@media (min-width:1281px) {
	.section {
		margin-bottom: 140px
	}
}

.section-2 {
	margin-bottom: 80px
}

@media (min-width:1281px) {
	.section-2 {
		margin-bottom: 160px
	}
}

.section-3 {
	margin-bottom: 40px
}

@media (min-width:1281px) {
	.section-3 {
		margin-bottom: 80px
	}
}

.show {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important
}

.block {
	display: block !important
}

.hide {
	display: none !important
}

.mb-30px {
	margin-bottom: 30px !important
}

.rotate {
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg)
}

.image-wrapper {
	overflow: hidden;
	padding-top: 100%;
	position: relative
}

@media (min-width:1281px) {
	.image-wrapper {
		height: 224px !important;
		min-width: 224px !important;
		overflow: auto;
		padding-top: 0;
		width: 224px !important
	}
}

.image-wrapper img {
	height: 100%;
	left: 0;
	-o-object-fit: cover;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%
}

@media (min-width:1281px) {
	.image-wrapper img {
		position: relative;
		width: 100% !important
	}
}

.all-rights-reserved {
	color: #b3c8db;
	font-size: 14px;
	line-height: 17px
}

@media (min-width:1281px) {
	.all-rights-reserved {
		font-size: 16px;
		line-height: 21px
	}
}

.all-rights-reserved.all-rights-reserved_footer {
	margin-bottom: 20px
}

@media (min-width:1281px) {
	.all-rights-reserved.all-rights-reserved_footer {
		margin-bottom: 0
	}
}

.before-breadcrumbs {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between
}

.before-breadcrumbs__vendor-code {
	color: #b3c8db
}

.breadcrumbs {
	color: #6d98ba;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 10px
}

.btn {
	border-radius: 8px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 22px;
	padding-bottom: 12px;
	padding-top: 10px;
	-webkit-transition: background-color .5s ease-in-out;
	transition: background-color .5s ease-in-out;
	width: 100%
}

.btn.disabled {
	background-color: #6d98ba
}

.btn.btn_submit {
	background-color: #ff8915;
	color: #fff
}

.btn.btn_submit:hover {
	background-color: #bc6916
}

.btn.btn_show {
	background-color: #09295a;
	color: #fff
}

.btn.btn_show:hover {
	background-color: #6d98ba
}

.show-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.show-btn.show-btn_white .show-btn__icon {
	fill: #fff
}

.show-btn.show-btn_white:hover .show-btn__name {
	color: #bebebe !important
}

.show-btn.show-btn_white:hover .show-btn__icon {
	fill: #bebebe !important
}

.show-btn .show-btn__icon,
.show-btn .show-btn__icon-2 {
	fill: #6d98ba;
	-webkit-transition: fill .5s ease-in-out, -webkit-transform .5s ease-in-out;
	transition: fill .5s ease-in-out, -webkit-transform .5s ease-in-out;
	transition: fill .5s ease-in-out, transform .5s ease-in-out;
	transition: fill .5s ease-in-out, transform .5s ease-in-out, -webkit-transform .5s ease-in-out
}

.show-btn:hover .show-btn__name {
	color: #09295a
}

.show-btn:hover .show-btn__icon {
	fill: #09295a;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg)
}

.show-btn:hover .show-btn__icon-2 {
	fill: #09295a
}

.show-btn.disabled .show-btn__name {
	color: #bebebe
}

.show-btn.show-btn_catalog {
	margin-bottom: 10px
}

.show-btn .show-btn__name {
	color: #6d98ba;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 22px;
	margin-right: 3px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.c-input {
	position: relative
}

.c-input__input {
	background-color: #f3f8ff;
	border-radius: 8px;
	padding: 10px 12px 12px;
	width: 400px
}

.c-input__input::-webkit-input-placeholder {
	color: #b3c8db
}

.c-input__input::-moz-placeholder {
	color: #b3c8db
}

.c-input__input:-ms-input-placeholder {
	color: #b3c8db
}

.c-input__input::-ms-input-placeholder {
	color: #b3c8db
}

.c-input__input::placeholder {
	color: #b3c8db
}

.c-input__icon {
	position: absolute;
	right: 12px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%)
}

.c-input-adaptive {
	display: none;
	position: relative;
	width: 100%
}

.c-input-adaptive input {
	background-color: #fff;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	padding: 2px 6px 4px;
	width: 100%
}

.c-input-adaptive input::-webkit-input-placeholder {
	color: #b3c8db
}

.c-input-adaptive input::-moz-placeholder {
	color: #b3c8db
}

.c-input-adaptive input:-ms-input-placeholder {
	color: #b3c8db
}

.c-input-adaptive input::-ms-input-placeholder {
	color: #b3c8db
}

.c-input-adaptive input::placeholder {
	color: #b3c8db
}

.c-input-adaptive svg {
	position: absolute;
	right: 12px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%)
}

.catalog-catalog {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

@media (min-width:1281px) {
	.catalog-catalog {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr;
		-webkit-column-gap: 40px;
		-moz-column-gap: 40px;
		column-gap: 40px;
		display: -ms-grid;
		display: grid;
		grid-template-columns: repeat(10, 1fr)
	}

	.catalog-catalog__left {
		-ms-grid-column-span: 2;
		grid-column: span 2
	}

	.catalog-catalog__right {
		-ms-grid-column-span: 8;
		grid-column: span 8
	}
}

.city {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.city:hover .city__name {
	color: #09295a
}

.city:hover .city__icon {
	fill: #09295a
}

.city__name {
	color: #6d98ba;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 22px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.city__icon {
	fill: #6d98ba;
	-webkit-transition: fill .5s ease-in-out;
	transition: fill .5s ease-in-out
}

.contacts.contacts_footer {
	margin-top: 50px
}

@media (min-width:1281px) {
	.contacts.contacts_footer {
		margin-top: 0
	}
}

.contacts__title {
	color: #3f6298;
	margin-bottom: 20px
}

.contacts__ul {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 20px
}

.contacts__ul,
.contacts__ul-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.contacts__ul-item {
	-webkit-column-gap: 12px;
	-moz-column-gap: 12px;
	column-gap: 12px
}

.contacts__ul-item-link {
	color: #6d98ba;
	font-size: 14px;
	line-height: 17px
}

@media (min-width:1281px) {
	.contacts__ul-item-link {
		font-size: 16px;
		line-height: 21px
	}
}

.contacts__ul-item-link-1 {
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.contacts__ul-item-link-1:hover {
	color: #09295a
}

.filter.filter_catalog {
	margin-bottom: 20px
}

.filter.filter_catalog .filter__inner {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	background-color: #f3f8ff;
	border-radius: 14px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 20px 20px 30px
}

.filter__item-box {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	align-items: center;
	justify-content: space-between
}

.filter__box,
.filter__item-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.filter__box {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 40px
}

.filter__title {
	margin-bottom: 40px
}

.filter__item-title {
	color: #3f6298;
	line-height: 21px
}

.filter__item-input {
	background-color: #fff;
	border-radius: 8px;
	color: #6d98ba;
	padding: 6px 12px 8px;
	width: 72px
}

.filter__item-decor {
	background-color: #6d98ba;
	height: 1px;
	width: 20px
}

@media (min-width:1281px) {
	.filter__btn {
		margin-top: 20px
	}
}

#footer {
	background-color: #f3f8ff;
	padding: 20px 0
}

@media (min-width:1281px) {
	#footer {
		padding: 40px 0 0
	}
}

.footer__top {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0 20px
}

@media (min-width:1281px) {
	.footer__top {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		margin-bottom: 40px;
		padding: 0
	}
}

.footer__bottom {
	border-top: 1px solid #b3c8db;
	padding: 20px 20px 0
}

@media (min-width:1281px) {
	.footer__bottom {
		padding: 5px 0 3px
	}

	.footer__bottom .container {
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between
	}
}

.footer__title {
	color: #3f6298
}

.footer__title_official_distributor_of_drilling_rigs {
	margin-bottom: 24px
}

@media (min-width:1281px) {
	.footer__title_official_distributor_of_drilling_rigs {
		font-size: 16px;
		line-height: 21px
	}
}

.header {
	border-bottom: 2px solid #f3f8ff
}

.header__top {
	background-color: #f3f8ff;
	height: 34px
}

@media (min-width:1281px) {
	.header__top {
		height: 28px
	}
}

.header__top-inner {
	height: 100%
}

@media (min-width:1281px) {
	.header__top-inner {
		margin: 0 auto;
		max-width: 1280px
	}
}

.header__bottom {
	height: 52px
}

@media (min-width:1281px) {
	.header__bottom {
		height: 70px
	}
}

.header__bottom-inner {
	height: 100%
}

@media (min-width:1281px) {
	.header__bottom-inner {
		margin: 0 auto;
		max-width: 1280px
	}
}

.header__bottom-inner,
.header__top-inner {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	padding: 0 20px
}

@media (min-width:1281px) {

	.header__bottom-inner,
	.header__top-inner {
		padding: 0
	}
}

.index-about-company__title {
	margin-bottom: 40px;
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-about-company__title {
		padding: 0
	}
}

.index-about-company__box {
	background-color: #09295a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 20px 40px;
	position: relative
}

@media (min-width:1281px) {
	.index-about-company__box {
		padding: 20px 0 20px 40px
	}
}

.index-about-company__img {
	border-radius: 8px;
	height: 100%;
	position: absolute;
	right: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%) scale(1.1);
	-ms-transform: translateY(-50%) scale(1.1);
	transform: translateY(-50%) scale(1.1);
	width: 170px
}

@media (min-width:1281px) {
	.index-about-company__img {
		bottom: -40px;
		height: 452px;
		right: calc(50vw - 640px);
		top: auto;
		-webkit-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 490px
	}
}

.index-about-company__ul {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 30px
}

@media (min-width:1281px) {
	.index-about-company__ul {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		gap: 50px
	}
}

.index-about-company__ul-item {
	position: relative;
	transition: all 0.3s ease;
}
.index-about-company__ul-item:hover {
	transform: translateY(-10px);
}

.index-about-company__ul-item:before {
	background-color: #fff;
	content: "";
	height: 100%;
	left: -20px;
	position: absolute;
	top: 0;
	width: 2px
}

@media (min-width:1281px) {
	.index-about-company__ul-item {
		width: 224px
	}
}

.index-about-company__ul-item-desc,
.index-about-company__ul-item-title {
	color: #fff
}

.index-about-company__text {
	color: #6d98ba;
	padding: 30px 20px 0
}

@media (min-width:1281px) {
	.index-about-company__text {
		margin-bottom: 80px;
		padding: 0 576px 0 0;
		line-height: 26px;
	}
}

.index-advantages__box-1 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-advantages__box-1 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-ms-flex-direction: row;
		flex-direction: row;
		justify-content: space-between;
		padding: 0
	}
}

.index-advantages__box-2 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	border-radius: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 10px
}

@media (min-width:1281px) {
	.index-advantages__box-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		background: -webkit-gradient(linear, left top, right top, from(rgba(172, 203, 238, .4)), to(rgba(231, 240, 253, .4)));
		background: linear-gradient(90deg, rgba(172, 203, 238, .4), rgba(231, 240, 253, .4));
		-webkit-column-gap: 10px;
		-moz-column-gap: 10px;
		column-gap: 10px;
		-ms-flex-direction: row;
		flex-direction: row;
		padding: 30px 40px
	}
}

.index-advantages__box-22 {
	-webkit-column-gap: 10px;
	-moz-column-gap: 10px;
	column-gap: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: scroll;
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-advantages__box-22 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow-x: visible !important;
		padding: 0;
		row-gap: 40px
	}
}

.index-advantages__title {
	color: #09295a
}

.index-advantages__title-2 {
	color: #ff8915
}

.index-advantages__box-21 {
	padding: 0 20px;
	width: 100% !important
}

@media (min-width:1281px) {
	.index-advantages__box-21 {
		padding: 0
	}
}

.index-advantages__box-211 {
	background: -webkit-gradient(linear, left top, right top, from(rgba(172, 203, 238, .4)), to(rgba(231, 240, 253, .4)));
	background: linear-gradient(90deg, rgba(172, 203, 238, .4), rgba(231, 240, 253, .4));
	border-radius: 8px;
	padding: 20px 30px
}

@media (min-width:1281px) {
	.index-advantages__box-211 {
		background: none;
		border-radius: 0;
		margin-bottom: 40px;
		margin-top: 20px;
		padding: 0;
		width: 100%
	}
}

.index-advantages__img {
	width: 100%
}

.index-advantages__item {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	background-color: #f3f8ff;
	border-radius: 6px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 280px;
	padding: 20px;
	row-gap: 6px;
	width: 280px;
	transition: all 0.3s ease;
}
.index-advantages__item:hover{
	transform: translateY(-10px);
}

@media (min-width:1281px) {
	.index-advantages__item {
		background: none;
		min-width: auto;
		padding: 10px;
		row-gap: 10px;
		width: 448px
	}
}

.index-advantages__item-title {
	color: #3f6298;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 21px
}

@media (min-width:1281px) {
	.index-advantages__item-title {
		font-size: 24px !important;
		font-weight: 500 !important;
		letter-spacing: -.01em !important;
		line-height: 33px !important
	}
}

.index-advantages__item-text {
	color: #6d98ba;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 17px
}

@media (min-width:1281px) {
	.index-advantages__item-text {
		font-size: 16px !important;
		font-weight: 400 !important;
		letter-spacing: 0 !important;
		line-height: 21px !important
	}
}

.index-drilling-rig-catalog__box {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-drilling-rig-catalog__box {
		padding: 0
	}
}

.items.items_index {
	padding: 0 20px
}
.slider_button__call{
	margin-top: 15px;
}

@media (min-width:1281px) {
	.items.items_index {
		padding: 0
	}
}

.index-interested-in-our-products {
	background-color: #09295a;
	padding: 40px 20px
}

@media (min-width:1281px) {
	.index-interested-in-our-products {
		padding: 40px 0
	}
}

.index-interested-in-our-products__box {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 20px
}

@media (min-width:1281px) {
	.index-interested-in-our-products__box {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-column-gap: 80px;
		-moz-column-gap: 80px;
		column-gap: 80px;
		-ms-flex-direction: row;
		flex-direction: row
	}
}

.index-interested-in-our-products__box .btn {
	width: 320px
}

.index-interested-in-our-products__box .show-btn__name {
	color: #fff
}

.index-interested-in-our-products__box .show-btn:hover .show-btn__name {
	color: #fff
}

.index-interested-in-our-products__title {
	color: #fff;
	margin-bottom: 30px
}

@media (min-width:1281px) {
	.index-interested-in-our-products__title {
		margin-bottom: 40px
	}
}

.show-btn__index-interested-in-our-products span {
	color: #fff;
	margin-right: 3px
}

.index-news {
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-news {
		padding: 0
	}
}

.index-news__box {
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	align-items: flex-end;
	justify-content: space-between
}

.index-news-items,
.index-news__box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.index-news-items {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 30px
}

@media (min-width:1281px) {
	.index-news-items {
		-ms-grid-columns: 1fr 80px 1fr;
		display: -ms-grid;
		display: grid;
		gap: 80px;
		grid-template-columns: 1fr 1fr
	}
}

.index-news-items__item {
	-ms-grid-columns: 1fr 10px 1fr;
	-webkit-column-gap: 10px;
	-moz-column-gap: 10px;
	column-gap: 10px;
	display: -ms-grid;
	display: grid;
	grid-template-columns: repeat(2, 1fr)
}

@media (min-width:1281px) {
	.index-news-items__item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
}

.index-news-items__item-img {
	-ms-grid-column-span: 1;
	border-radius: 12px;
	grid-column: span 1;
	height: 100%;
	width: 100%
}

@media (min-width:1281px) {
	.index-news-items__item-img {
		height: 224px;
		min-width: 224px;
		width: 224px
	}
}

.index-news-items__item-date {
	color: #b3c8db;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	margin-bottom: 6px
}

@media (min-width:1281px) {
	.index-news-items__item-date {
		margin-bottom: 0
	}
}

.index-news-items__item-title {
	color: #09295a;
	cursor: pointer;
	font-size: 16px;
	line-height: 21px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.index-news-items__item-title:hover {
	color: #000
}

@media (min-width:1281px) {
	.index-news-items__item-title {
		font-family: Manrope;
		font-size: 24px;
		font-weight: 500;
		letter-spacing: -.01em;
		line-height: 33px
	}
}

.index-news-items__item-text {
	color: #6d98ba;
	font-size: 14px;
	line-height: 17px;
	margin-top: 8px
}

@media (min-width:1281px) {
	.index-news-items__item-text {
		font-size: 16px;
		line-height: 21px
	}
}

.index-news__items-item-box-1 {
	-ms-grid-column-span: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	grid-column: span 1;
	justify-content: space-between
}

.index-partners__title {
	padding: 0 20px
}

@media (min-width:1281px) {
	.index-partners__title {
		padding: 0
	}
}

.index-partners-items {
	-webkit-column-gap: 10px;
	-moz-column-gap: 10px;
	column-gap: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: scroll;
	padding-left: 20px
}

@media (min-width:1281px) {
	.index-partners-items {
		-ms-grid-columns: (1fr)[7];
		display: -ms-grid;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		overflow-x: auto;
		padding-left: 0
	}
}

.index-partners-items__item {
	cursor: pointer;
	position: relative
}

.index-partners-items__item:before {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 12px;
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transition: background-color .5s ease-in-out;
	transition: background-color .5s ease-in-out
}

.index-partners-items__item:hover:before {
	background-color: rgba(0, 0, 0, .15)
}

.index-partners-items__item-img {
	height: 100px;
	-o-object-fit: contain;
	object-fit: contain;
	width: 100px
}

@media (min-width:1281px) {
	.index-partners-items__item-img {
		height: 100%;
		width: 100%
	}
}

.items-categories {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between
}

.items-categories.items-categories_catalog {
	margin-top: 60px
}

.items-categories__item {
	color: #6d98ba;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 22px
}

.items.items_index {
	-webkit-column-gap: 20px;
	-moz-column-gap: 20px;
	column-gap: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: scroll
}

.items.items_index .items__item-img {
	width: 224px;
	object-fit: contain;
}
.slider_product {
	width: 100%;
}
.card-body form {
	text-align: center;
  }
  .card-body input {
	padding: 15px 45px;
	border-radius: 5px;
  }
  .card-header{
	text-align: center;
	font-size: 36px;
	margin-top: 30px;
	margin-bottom: 30px;
  }
  .btn--primary{
	background: #09295a;
	width: 250px;
	color: #fff;
	margin-top: 20px;
	margin-bottom: 20px;
  }
.items.items_catalog {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 30px;
}
.items__item { 
	transition: all 0.3s ease;
}
.items__item:hover {
	transform: translateY(-10px);
}


@media (min-width:1281px) {
	.items.items_catalog {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;
		display: -ms-grid;
		display: grid;
		gap: 40px;
		grid-template-columns: 1fr 1fr 1fr 1fr
	}
}

.items.items_catalog .items__item-img {
	width: 100%;
	object-fit: contain;
}

.items__item-img {
	border-radius: 12px;
	height: 200px;
	object-fit: contain;
    width: 100%;
}

.items__item-vendor-code {
	color: #6d98ba;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.items__item-vendor-code:hover {
	color: #09295a
}

.items__item-name {
	color: #6d98ba;
	cursor: pointer;
	line-height: 21px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out;
	height: 90px;
}

.items__item-name:hover {
	color: #09295a
}

.items__item-price {
	color: #09295a;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 16px;
	margin-top: 10px
}

.logo:hover .logo__icon-path-1,
.logo:hover .logo__icon-path-2 {
	fill: #000
}

.logo.logo_header .logo__icon {
	height: 36px
}

@media (min-width:1281px) {
	.logo.logo_header .logo__icon {
		height: 60px
	}
}

.logo.logo_footer {
	margin-bottom: 24px
}

.logo.logo_footer .logo__icon {
	height: 32px
}

@media (min-width:1281px) {
	.logo.logo_footer .logo__icon {
		height: 60px
	}
}

.logo__icon-path-1,
.logo__icon-path-2 {
	fill: #09295a;
	-webkit-transition: fill .25s ease-in-out;
	transition: fill .25s ease-in-out
}

.mailing {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 24px
}

.mailing.mailing_footer {
	margin-top: 50px
}

@media (min-width:1281px) {
	.mailing.mailing_footer {
		margin-top: 0
	}
}

.mailing__title {
	color: #3f6298
}

.mailing__text {
	color: #6d98ba;
	font-size: 14px;
	line-height: 17px
}

@media (min-width:1281px) {
	.mailing__text {
		font-size: 16px;
		line-height: 21px
	}
}

.mailing__box {
	row-gap: 4px
}

.mailing__box,
.mailing__form {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.mailing__form {
	row-gap: 8px
}

.mailing__form-input {
	background-color: #fff;
	border-radius: 8px;
	padding: 10px 12px
}

@media (min-width:1281px) {
	.mailing__form-input {
		width: 320px
	}
}

.mailing__form-input::-webkit-input-placeholder {
	color: #6d98ba
}

.mailing__form-input::-moz-placeholder {
	color: #6d98ba
}

.mailing__form-input:-ms-input-placeholder {
	color: #6d98ba
}

.mailing__form-input::-ms-input-placeholder {
	color: #6d98ba
}

.mailing__form-input::placeholder {
	color: #6d98ba
}

.menu {
	background-color: #fff;
	display: none;
	height: 100%;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 90
}

.menu .menu-content {
	padding: 50px 60px 50px 20px
}

.menu .menu-content__ul {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 26px
}

.menu .menu-content__ul-item {
	color: #09295a;
	font-family: Manrope;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 33px
}

.menu .show-btn__menu {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -.01em;
	line-height: 22px;
	margin-top: 30px
}

.modal-background,
.modal-background-2 {
	background-color: rgba(0, 0, 0, .3);
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0
}

.modal {
	background-color: #fff;
	border-radius: 18px;
	display: none;
	left: 50%;
	overflow: auto;
	position: fixed;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: calc(100% - 40px);
	z-index: 100
}

@media (min-width:1281px) {
	.modal {
		width: auto
	}
}

.modal .modal-content {
	width: 100%
}

.modal.modal_thanks .modal-content {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 20px 20px 30px
}

@media (min-width:1281px) {
	.modal.modal_thanks .modal-content {
		padding: 50px 50px 60px
	}
}

.modal.modal_thanks .modal-content__icon {
	height: 100px;
	margin-bottom: 20px;
	width: 100px
}

@media (min-width:1281px) {
	.modal.modal_thanks .modal-content__icon {
		height: 120px;
		margin-bottom: 36px;
		width: 120px
	}
}

.modal.modal_thanks .modal-content__title {
	margin-bottom: 6px
}

@media (min-width:1281px) {
	.modal.modal_thanks .modal-content__title {
		color: #09295a;
		margin-bottom: 8px
	}
}

.modal.modal_thanks .modal-content__text {
	margin-bottom: 20px
}

@media (min-width:1281px) {
	.modal.modal_thanks .modal-content__text {
		color: #6d98ba;
		margin-bottom: 36px
	}
}

.modal.modal_leave-a-request .modal-content {
	display: block
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
}

.modal.modal_leave-a-request .modal-content__left {
	display: none;
	padding: 24px 24px 30px
}
@media (max-width:1200px) {
	.modal.modal_leave-a-request .modal-content__left {
		padding: 24px 24px 0px
	}
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__left {
		display: block
	}
}

.modal.modal_leave-a-request .modal-content__item-img {
	border-radius: 12px;
	height: 200px;
	width: 224px;
	object-fit: contain;
}

.modal.modal_leave-a-request .modal-content__item-vendor-code {
	color: #b3c8db;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px
}

.modal.modal_leave-a-request .modal-content__item-name {
	color: #6d98ba;
	line-height: 21px;
	margin-bottom: 10px
}

.modal.modal_leave-a-request .modal-content__item-price {
	color: #09295a;
	font-size: 18px;
	line-height: 25px
}

.modal.modal_leave-a-request .modal-content__right {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	background-color: #f3f8ff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 20px 20px 30px
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__right {
		padding: 30px 40px 30px 24px
	}
}

.modal.modal_leave-a-request .modal-content__title {
	margin-bottom: 6px
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__title {
		color: #09295a;
		margin-bottom: 8px
	}
}

.modal.modal_leave-a-request .modal-content__text {
	margin-bottom: 20px
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__text {
		color: #6d98ba;
		margin-bottom: 30px
	}
}

.modal.modal_leave-a-request .modal-content__form {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.modal.modal_leave-a-request .modal-content__input {
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 12px;
	padding: 8px 12px 10px;
	width: 100%
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__input {
		width: 320px
	}
}

.modal.modal_leave-a-request .modal-content__input:last-of-type {
	margin-bottom: 0
}

.modal.modal_leave-a-request .modal-content__btn {
	margin-top: 20px
}

@media (min-width:1281px) {
	.modal.modal_leave-a-request .modal-content__btn {
		margin-top: 30px;
		width: 320px
	}
}

.modal__close {
	height: 32px;
	position: absolute;
	right: 16px;
	top: 16px;
	width: 32px
}

.nav.nav_header .nav__ul {
	-webkit-column-gap: 50px;
	-moz-column-gap: 50px;
	column-gap: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.nav.nav_header .nav__ul-item {
	color: #09295a;
	line-height: 20.8px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.nav.nav_header .nav__ul-item:hover {
	color: #6d98ba
}

.nav.nav_footer {
	margin-top: 50px
}

@media (min-width:1281px) {
	.nav.nav_footer {
		margin-top: 0
	}
}

.nav.nav_footer .nav__title {
	color: #3f6298;
	margin-bottom: 20px
}

.nav.nav_footer .nav__ul {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 16px
}

.nav.nav_footer .nav__ul-item {
	color: #6d98ba;
	font-size: 14px;
	line-height: 17px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.nav.nav_footer .nav__ul-item:hover {
	color: #09295a
}

@media (min-width:1281px) {
	.nav.nav_footer .nav__ul-item {
		font-size: 16px;
		line-height: 21px
	}
}

.policy {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 6px
}

.policy__item {
	color: #b3c8db;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	-webkit-transition: color .5s ease-in-out;
	transition: color .5s ease-in-out
}

.policy__item:hover {
	color: #09295a
}

.pop-up-container {
	padding: 0 20px
}

.pop-up {
	background-color: #fff;
	border-radius: 0 0 24px 24px;
	display: none;
	left: 0;
	padding: 30px 40px;
	position: absolute;
	top: 58px;
	width: 400px;
	z-index: 70
}

.pop-up.pop-up_mobile {
	background-color: #f3f8ff;
	display: block;
	left: auto !important;
	position: relative !important;
	top: auto !important;
	width: auto
}

.pop-up .filter__item-title {
	color: #6d98ba;
	font-size: 18px;
	line-height: 25px
}

.pop-up__title {
	color: #09295a
}

.pop-up__icon {
	height: 46px;
	width: 46px
}

.product-advantages-items {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 30px
}

@media (min-width:1281px) {
	.product-advantages-items {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr;
		-webkit-column-gap: 40px;
		-moz-column-gap: 40px;
		column-gap: 40px;
		display: -ms-grid;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr
	}
}

.product-advantages-items__item-title {
	color: #3f6298;
	margin-bottom: 6px
}

.product-advantages-items__item-img {
	border-radius: 12px;
	height: 230px;
	margin-bottom: 10px;
	width: 100%
}

@media (min-width:1281px) {
	.product-advantages-items__item-img {
		margin-bottom: 20px
	}
}

.product-advantages-items__item-text {
	color: #6d98ba
}

.product-info-characteristics-items__item {
	margin-bottom: 15px;
	width: 100%
}

.product-info-characteristics-items__item:last-of-type {
	margin-bottom: 0
}

@media (min-width:1281px) {
	.product-info-characteristics-items__item {
		width: 488px
	}
}

.product-info-characteristics-items__item-contents {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: none;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 16px;
	row-gap: 8px
}

@media (min-width:1281px) {
	.product-info-characteristics-items__item-contents {
		row-gap: 10px
	}
}

.product-info-characteristics-items__item-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%
}

.product-info-characteristics-items__item-decor {
	border-bottom: 1px dashed #000;
	width: 100%
}

.product-info-characteristics-items__item-content-name {
	color: #7f7f7f;
	line-height: 21px;
	white-space: nowrap
}

.product-info-characteristics-items__item-content-value {
	color: #3f6298;
	line-height: 21px;
	white-space: nowrap
}

.before-product-info {
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px
}

.before-product-info__title {
	color: #09295a;
	width: 85%;
	
}

.before-product-info__price {
	color: #3f6298
}

.after-product-info {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 10px;
	row-gap: 6px
}

.after-product-info__title {
	color: #09295a
}

.after-product-info__price {
	color: #3f6298
}

.product-info {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 30px
}

@media (min-width:1281px) {
	.product-info {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr;
		-webkit-column-gap: 40px;
		-moz-column-gap: 40px;
		column-gap: 40px;
		display: -ms-grid;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
	}
}

.product-info__left {
	-ms-grid-column-span: 4;
	grid-column: span 4
}

.product-info__left .product-info__left-box-1 {
	-webkit-column-gap: 10px;
	-moz-column-gap: 10px;
	column-gap: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.product-info__left .product-info-preview {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	row-gap: 8px
}

.product-info__left .product-info-preview,
.product-info__left .product-info-preview__box {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}

.product-info__left .product-info-preview__box {
	height: 290px;
	overflow-y: scroll;
	row-gap: 5px
}

.product-info__left .product-info-preview__item-img {
	border-radius: 9px;
	cursor: pointer;
	height: 54px;
	width: 60px
}

.product-info__left .product-info-big-preview {
	width: 100%
}

.product-info__left .product-info-big-preview__item-img {
	border-radius: 12px;
	height: 283px;
	width: 100%;
	object-fit: contain;
}
.news_page img {
	object-fit: cover!important;
}
.row_article {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.row_article .col-lg-8 {
	width: 70%;
}
.row_article .col-lg-3 {
	width: 20%;
}
.row_article img{
	width: 50%;
}
.other_title {
	font-size: 22px;
}
.article_title {
	margin-bottom: 20px;
}
.row_article .images {
	margin-top: 30px;
}
.swiper-slide a {
	transition: all 0.3s ease;
}
.swiper-slide a:hover {
	transform: translateY(-10px);
}
@media (max-width:1200px) {
	.index-advantages__box-22 {
		display: block;
	}
	.index-advantages__item {
		width: 100%;
		margin-bottom: 10px;
	}
	.swiper-backface-hidden .swiper-slide {
		padding: 20px;
	}
	.row_article {
		display: block;
		padding: 20px;
		
	}
	.row_article img{
		width: 100%;
	}
	.row_article .col-lg-8 {
		width: 100%;
	}
	.row_article .col-lg-3 {
		width: 100%;
		margin-top: 20px;
	}
}

@media (min-width:1281px) {
	.product-info__left .product-info-big-preview__item-img {
		height: 369px
	}
}

.product-info__left .product-info__btn {
	margin-top: 20px
}

.product-info__left .product-info__left-box-2 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 20px;
	row-gap: 8px
}
.cook_win {
	display: none; 
	position: fixed; 
	bottom: 0; 
	right: 0; 
	width: 25%; 
	background-color: white; 
	border: 1px solid #ccc; 
	border-radius: 8px;
	padding: 15px; box-shadow: 0 0 10px rgba(0,0,0,0.5);
	z-index: 99999;
}
@media (max-width:1200px) {
	.cook_win {
		width: 100%; 
	}
}
.cook_win p {
	text-align: center;
	margin-bottom: 15px;
}
.cook_win a {
	color: #ff8916;
}
.product-info__right {
	-ms-grid-column-span: 6;
	grid-column: span 6
}

.product-info__right .product-info__title {
	color: #6d98ba;
	margin-bottom: 30px
}

.product-info__right .product-info__text {
	color: #7f7f7f
}

.product-info__right .product-info__box-1 {
	margin-bottom: 50px
}

.product-popular__box {
	padding: 0 20px
}

@media (min-width:1281px) {
	.product-popular__box {
		padding: 0
	}
}

.product-popular__box,
.product-recommended-spare-parts__box {
	-webkit-box-align: end;
	-ms-flex-align: end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between
}

.sliders_control {
	height: 16px;
	margin-bottom: 8px;
	margin-top: 12px;
	position: relative
}

.form_control {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	position: relative
}

input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	background-color: #09295a;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	height: 16px;
	pointer-events: all;
	width: 16px
}

input[type=range]::-moz-range-thumb {
	-webkit-appearance: none;
	background-color: #09295a;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	height: 16px;
	pointer-events: all;
	width: 16x
}

input[type=range]::-webkit-slider-thumb:hover {
	background: #09295a
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	opacity: 1
}

input[type=range] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #09295a;
	height: 2px;
	pointer-events: none;
	position: absolute;
	width: 100%
}

#fromSlider {
	height: 0;
	z-index: 1
}

.socials {
	-webkit-column-gap: 8px;
	-moz-column-gap: 8px;
	column-gap: 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.socials__item-link:hover .socials__item-icon {
	fill: #09295a
}

.socials__item-icon {
	fill: #b3c8db;
	-webkit-transition: fill .5s ease-in-out;
	transition: fill .5s ease-in-out
}

.splash {
	overflow: hidden;
	position: relative
}

.splash__img {
	height: 100%;
	max-height: 752px;
	min-height: 360px;
	width: 100%
}

.slider {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: -webkit-transform .5s ease-in-out;
	transition: -webkit-transform .5s ease-in-out;
	transition: transform .5s ease-in-out;
	transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out
}

.slide {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	position: relative
}

.splash__overlay {
	background-color: rgba(0, 0, 0, .4);
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 50
}

.splash__title {
	bottom: 40px;
	color: #fff;
	font-family: manrope;
	font-size: 32px;
	font-weight: 700;
	left: 20px;
	letter-spacing: -.02em;
	line-height: 35px;
	position: absolute;
	z-index: 60
}

@media (min-width:1281px) {
	.splash__title {
		font-size: 72px;
		font-weight: 800;
		left: calc(50vw - 640px);
		line-height: 72px
	}
}

.support-you-can-count-on-items {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	row-gap: 10px
}

@media (min-width:1281px) {
	.support-you-can-count-on-items {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr;
		-webkit-column-gap: 40px;
		-moz-column-gap: 40px;
		column-gap: 40px;
		display: -ms-grid;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr
	}
}

.support-you-can-count-on-items__item {
	background-color: #f3f8ff;
	border-radius: 12px;
	padding: 20px
}

.support-you-can-count-on-items__item-title {
	color: #3f6298;
	margin-bottom: 10px
}

.support-you-can-count-on-items__item-text {
	color: #6d98ba
}

.working {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 20px;
	row-gap: 30px
}

@media (min-width:576px) {
	.working {
		padding: 100px
	}
}

.working__img {
	width: 224px
}

@media (min-width:576px) {
	.working__img {
		width: 512px
	}
}

.working__text {
	font-size: 24px;
	line-height: normal;
	text-align: center
}

@media (min-width:576px) {
	.working__text {
		font-size: 32px
	}
}

.contact_us_green * {
  font-family: Nunito, sans-serif;
}

.contact_us_green .responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
}

.contact_us_green input:focus {
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
}

.contact_us_green textarea:focus {
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
}

.contact_us_green .text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  line-height: 25px;
}

.contact_us_green .responsive-cell-block {
  min-height: 75px;
}

.contact_us_green .responsive-container-block.container {
  max-width: 1320px;
  margin-top: 60px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
}

.contact_us_green .responsive-container-block.big-container {
  padding-top: 0px;
  padding-right: 50px;
  padding-bottom: 0px;
  padding-left: 50px;
}

.contact_us_green .text-blk.contactus-head {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

.contact_us_green .text-blk.contactus-subhead {
  max-width: 385px;
  color: #939393;
  font-size: 18px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
}

.contact_us_green .contact-svg {
  padding-top: 0px;
  padding-right: 25px;
  padding-bottom: 0px;
  padding-left: 0px;
  width: 65px;
  height: 40px;
}

.contact_us_green .social-media-links {
  margin-top: 80px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  width: 250px;
  display: flex;
  justify-content: space-evenly;
}

.contact_us_green .social-svg {
  width: 35px;
  height: 35px;
}

.contact_us_green .text-box {
  display: flex;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
}

.contact_us_green .contact-text {
  color: #939393;
}

.contact_us_green .input {
  height: 50px;
  width: 90%;
  border-top-width: 2.5px;
  border-right-width: 2.5px;
  border-bottom-width: 2.5px;
  border-left-width: 2.5px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #a2a2a2;
  border-right-color: #a2a2a2;
  border-bottom-color: #a2a2a2;
  border-left-color: #a2a2a2;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.contact_us_green .textinput {
  height: 200px;
  width: 95%;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #a2a2a2;
  border-right-color: #a2a2a2;
  border-bottom-color: #a2a2a2;
  border-left-color: #a2a2a2;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.contact_us_green .submit-btn {
  min-width: 290px;
  height: 60px;
  background-color: #1c3b28;
  font-size: 18px;
  font-weight: 700;
  color: white;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  cursor: pointer;
}

.contact_us_green .btn-wrapper {
  display: flex;
  justify-content: center;
}

.contact_us_green .text-blk.input-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.contact_us_green .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 30px;
  margin-left: 0px;
}

.contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_us_green .head-text-box {
  display: none;
}

.contact_us_green .line {
  border-right-width: 1.8px;
  border-right-style: solid;
  border-right-color: #a2a2a2;
}

.contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-7.wk-ipadp-10.line {
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
}

@media (max-width: 1024px) {
  .contact_us_green .responsive-container-block.container {
    justify-content: center;
  }

  .contact_us_green .text-blk.contactus-subhead {
    max-width: 90%;
  }

  .contact_us_green .head-text-box {
    display: block;
  }

  .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-7.wk-ipadp-10.line {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 60px;
    padding-left: 0px;
  }

  .contact_us_green .line {
    border-right-width: initial;
    border-right-style: none;
    border-right-color: initial;
    border-bottom-width: 1.8px;
    border-bottom-style: solid;
    border-bottom-color: #a2a2a2;
  }

  .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
    margin-top: 60px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .contact_us_green .workik-contact-bigbox {
    display: flex;
  }

  .contact_us_green .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-5.wk-ipadp-10 {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .contact_us_green .text-content {
    display: none;
  }

  .contact_us_green .input {
    width: 100%;
  }

  .contact_us_green .textinput {
    width: 100%;
  }

  .contact_us_green .text-blk.contactus-head {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .contact_us_green .responsive-container-block.big-container {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .contact_us_green .workik-contact-bigbox {
    display: block;
  }

  .contact_us_green .text-blk.input-title {
    font-size: 16px;
  }

  .contact_us_green .text-blk.contactus-head {
    font-size: 26px;
  }

  .contact_us_green .text-blk.contactus-subhead {
    font-size: 16px;
    line-height: 23px;
  }

  .contact_us_green .input {
    height: 45px;
  }

  .contact_us_green .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
    margin: 0 0 25px 0;
  }
}

.contacts_page {
	display: flex;
	padding-top: 50px;
    padding-bottom: 50px;
}
.contacts_page .contact_map {
	width: 50%;
}
.contacts_page .contact_info {
	width: 50%;
	padding-left: 25px;
	margin-top: 40px;
}
.contacts_page .contact_info__items {
	margin-top: 30px;
}
.contacts_page .contact_info__item {
	display: flex;
    align-items: center;
	margin-bottom: 15px;
}
.contacts_page .contact_info__item svg {
	margin-right: 10px;
}

@media (max-width: 1200px) {
	.contacts_page{
		display: block;
	}
	.contacts_page .contact_map {
		width: 100%;
	}
	.contacts_page .contact_info {
		width: 100%;
	}

}

.responsive-container-block {
	min-height: 75px;
	height: fit-content;
	width: 100%;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 0px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	justify-content: flex-start;
  }
  
  .text-blk {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	line-height: 25px;
  }
  
  .responsive-container-block.bigContainer {
	padding-top: 10px;
	padding-right: 30px;
	padding-bottom: 10px;
	padding-left: 30px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  
  .mainImg {
	color: black;
	width: 35%;
	height: 390px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px 7px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
  }
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
	padding: 10px;
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .city-list {
    list-style-type: none;
    padding: 15px;
  }

  .city-list li {
    padding: 10px;
    cursor: pointer;
  }

  .city-list li:hover {
    background-color: #ddd;
  }
  
  .text-blk.headingText {
	font-size: 25px;
	font-weight: 700;
	line-height: 34px;
	color: rgb(51, 51, 51);
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
  }
  
  .allText {
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 40px;
	width: 60%;
	margin: 0 0 0 0;
  }
  
  .text-blk.subHeadingText {
	color: rgb(102, 102, 102);
	font-size: 21px;
	line-height: 26px;
	font-weight: 700;
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 15px;
	margin-left: 0px;
  }
  
  .text-blk.description {
	font-size: 16px;
	line-height: 20px;
	color: rgb(102, 102, 102);
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 50px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
  }
  
  .explore {
	font-size: 20px;
	line-height: 28px;
	color: rgb(255, 255, 255);
	background-color: #ff8916;
	padding-top: 10px;
	padding-right: 50px;
	padding-bottom: 10px;
	padding-left: 50px;
	border-top-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 0px;
	border-left-width: 0px;
	border-top-style: outset;
	border-right-style: outset;
	border-bottom-style: outset;
	border-left-style: outset;
	border-top-color: #ff8916;
	border-right-color:#ff8916;
	border-bottom-color: #ff8916;
	border-left-color: #ff8916;
	border-image-source: initial;
	border-image-slice: initial;
	border-image-width: initial;
	border-image-outset: initial;
	border-image-repeat: initial;
	cursor: pointer;
	border-radius: 8px;
  }
  
  .explore:hover {
	background-image: initial;
	background-position-x: initial;
	background-position-y: initial;
	background-size: initial;
	background-repeat-x: initial;
	background-repeat-y: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: #a65200;
  }

  .city-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px;
	top: 30px;
	z-index: 99999;
	}
	.city-list div {
		padding: 10px 5px;
		cursor: pointer;
	}
	.city-list div:hover{
		background:#f3f8ff;
	}

	.city-list.open {
		display: block; /* Показать, когда добавляется класс .open */
	}

	.hidden {
		display: none;
	}

  
  .responsive-container-block.Container {
	margin-top: 80px;
	margin-right: auto;
	margin-bottom: 50px;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	max-width: 1320px;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
  }
  
  .responsive-container-block.Container.bottomContainer {
	margin-top: 100px;
	margin-right: 0px;
	margin-bottom: 50px;
	margin-left: 0px;
	flex-direction: row-reverse;
	margin: 100px auto 50px auto;
  }
  
  .allText.aboveText {
	margin: 0 0 0 40px;
  }
  
  .allText.bottomText {
	margin: 0 40px 0 0;
  }
  
  @media (max-width: 1024px) {
	.responsive-container-block.Container {
	  max-width: 850px;
	}
  
	.mainImg {
	  width: 35%;
	  height: auto;
	}
  
	.text-blk.description {
	  font-size: 20px;
	}
  
	.allText {
	  width: 60%;
	  margin-top: 0px;
	  margin-right: 0px;
	  margin-bottom: 0px;
	  margin-left: 20px;
	}
  
	.responsive-container-block.bigContainer {
	  padding-top: 10px;
	  padding-right: 10px;
	  padding-bottom: 10px;
	  padding-left: 10px;
	}
  
	.text-blk.subHeadingText {
	  font-size: 22px;
	}
  
	.responsive-container-block.Container.bottomContainer {
	  margin: 80px auto 50px auto;
	}
  
	.responsive-container-block.Container {
	  max-width: 830px;
	}
  
	.allText.aboveText {
	  margin: 30px 0 0 40px;
	}
  
	.allText.bottomText {
	  margin: 30px 40px 0 0;
	}
  }
  
  @media (max-width: 768px) {
	.mainImg {
	  width: 90%;
	}
  
	.allText {
	  width: 100%;
	  margin-top: 0px;
	  margin-right: 0px;
	  margin-bottom: 0px;
	  margin-left: 0px;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	  padding-top: 0px;
	  padding-right: 0px;
	  padding-bottom: 0px;
	  padding-left: 0px;
	}
  
	.responsive-container-block.Container {
	  flex-direction: column;
	  height: auto;
	}
  
	.text-blk.headingText {
	  text-align: center;
	}
  
	.text-blk.subHeadingText {
	  text-align: left;
	  font-size: 18px;
	}
  
	.text-blk.description {
	  text-align: left;
	  font-size: 18px;
	}
  
	.allText {
	  margin-top: 40px;
	  margin-right: 0px;
	  margin-bottom: 0px;
	  margin-left: 0px;
	}
  
	.allText.aboveText {
	  margin: 40px 0 0 0;
	}
  
	.responsive-container-block.Container {
	  margin: 80px auto 50px auto;
	}
  
	.responsive-container-block.Container.bottomContainer {
	  margin: 50px auto 50px auto;
	}
  
	.allText.bottomText {
	  margin: 40px 0 0 0;
	}
  }
  
  @media (max-width: 500px) {
	.responsive-container-block.Container {
	  padding-top: 10px;
	  padding-right: 0px;
	  padding-bottom: 10px;
	  padding-left: 0px;
	  width: 100%;
	  max-width: 100%;
	}
  
	.mainImg {
	  width: 100%;
	}
  
	.responsive-container-block.bigContainer {
	  padding-top: 10px;
	  padding-right: 25px;
	  padding-bottom: 10px;
	  padding-left: 25px;
	}
  
	.text-blk.subHeadingText {
	  font-size: 18px;
	  padding-top: 0px;
	  padding-right: 0px;
	  padding-bottom: 0px;
	  padding-left: 0px;
	}
  
	.text-blk.description {
	  font-size: 18px;
	  padding-top: 0px;
	  padding-right: 0px;
	  padding-bottom: 0px;
	  padding-left: 0px;
	}
  
	.allText {
	  padding-top: 0px;
	  padding-right: 0px;
	  padding-bottom: 0px;
	  padding-left: 0px;
	  width: 100%;
	}
  }

 .success_send {
	width: 30%;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
 }
 .success_send a {
	margin-top: 30px;
 }
.broshura__title {
	margin-top: 10px;
	margin-bottom: 30px;
}
.broshura_price {
	margin-bottom: 20px;
}
.broshura {
	width: 100%;
	display: none;
	text-align: center;
	padding-bottom: 100px;
}
.broshura_img img {
	width: 50%;
	height: 250px;
	margin: 0 auto;
}
.broshura .product-info-characteristics-items__item {
	margin: 0 auto;
}
.policy_text {
	margin-top: 10px;
}
.policy_text a {
	color: #ff8916;
}
 @media (max-width: 1200px) {
	.success_send {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	.index-about-company__img {
		display: none;
	}
 }

 @media print {
	.btn_submit, .cook_win, .product-info__left-box-2, header, footer, .breadcrumbs, .support-you-can-count-on, .product-advantages, .product-info__left-box-2{
		display: none!important;
	}
 }